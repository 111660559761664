* {
  font-family: 'Karla', serif;
  box-sizing: border-box;
  color: #490400;
}

.App {
  background-color: #F4EBE2;
}

header {
  box-shadow: 0 0 0 10px #F4EBE2 inset, 0 0 0 18px #D0343D inset, 0 0 0 22px #F4EBE2 inset, 0 0 0 24px #D0343D inset;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

.App-header h1 {
  font-family: 'Coustard', sans-serif;
  text-transform: uppercase;
  color: #D0343D;
  font-size: 2.5em;
  line-height: 1;
  margin: 0;
}

.subheader { 
  margin: 0;
  font-size: .8em;
}

.product {
  display: flex;
  align-items: center;
  background: #EBDCCE;
  border-radius: 7px;
  padding: 1em 1em;
  justify-content: flex-start;
  margin: 3em 4em;
  width: calc(100% - 6em);
  max-width: 400px;
  height: 175px;
  }

.productImage {
  width: 120px;
  height: 100%;
  background-position: center;
  background-size: 150%;
  background-repeat: no-repeat;
  margin-right: 1em;
  flex-shrink: 0;
}

.productName {
  width: auto;
  min-width: 170px;
  text-align: left;
  font-size: 1em;
}

.newProduct {
  background: #D0343D;
  border-radius: 20px 30px 18px 32px;
  border: none;
  color: #F4EBE2;
  font-size: .8em;
  padding: .5em 1em;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}